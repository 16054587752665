<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Kunden</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/customers/new" class="button button-orange button-small">Kunde hinzufügen ›</router-link></p>
            </div>
          </header>

          <div class="admin-body">

            <div class="search-wrap">
              <input v-model="search_phrase" v-on:input="search_handler" type="text" class="search-field" placeholder="Suchbegriff eingeben...">
            </div>

            <div v-if="loading">
              <div class="loading-wrap">
                <div class="loading-spinner"></div>
              </div>
            </div>
            <div v-else>

              <div v-if="search_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>

                <table class="table">
                  <thead>
                    <tr>
                      <th width="5%"></th>
                      <th width="40%">Nachname, Vorname</th>
                      <th width="45%">E-Mail</th>
                      <th width="10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="customer in customers" v-bind:key="customer.id">
                      <td>
                        <span v-if="customer.trust_level == 'good'" class="status status-green"></span>
                        <span v-if="customer.trust_level == 'medium'" class="status status-yellow"></span>
                        <span v-if="customer.trust_level == 'bad'" class="status status-red"></span>
                      </td>
                      <td>
                        <router-link :to="'/admin/customers/'+customer.id">
                          {{ customer.last_name }}, {{ customer.first_name }}
                        </router-link>
                      </td>
                      <td>
                        <router-link :to="'/admin/customers/'+customer.id">
                          {{ customer.email }}
                        </router-link>
                      </td>
                      <td>
                        <router-link :to="'/admin/customers/'+customer.id">
                          <span class="material-icons">create</span>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_customers',
  components: {
    AdminNav
  },
  data () {
    return {
      customers: [],
      loading: true,
      search_phrase: "",
      search_loading: false
    }
  },
  methods: {
    get_customers() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/customers', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.customers = response.data.customers;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      })

    },
    search_handler() {
      if (this.search_loading == false) {
        this.search_loading = true;
        axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/customers?search_phrase='+this.search_phrase, { headers: { Authorization: this.$store.getters.getToken } })
        .then(response => {
          this.customers = response.data.customers;
          this.search_loading = false;
        })
        .catch(error => {
          console.log(error);
        })
      }
    }
  },
  mounted () {
    this.get_customers();
  }
}
</script>

<style lang="scss">

</style>
